import React from 'react';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

import styles from './ourculture.module.css';
import { isMobile } from 'react-device-detect';
import { East } from '@mui/icons-material';



const CultureProject = () => {

    const navigate = useNavigate();

    return (
        <Grid className={styles.culture_container} 
            sx={{paddingBottom: "120px", paddingTop: "120px", paddingLeft: isMobile? "18px": "0px", paddingRight: isMobile? "18px": "0px"}}>
            <Grid className={styles.culture_subcontainer} >
                <Typography variant="h2" component={"h2"} 
                    className={styles.culture_title} 
                    sx={{marginBottom:"2rem", marginLeft: isMobile? "18px": "0px", marginRight: isMobile? "18px": "0px"}}>
                    We Put Our People First
                </Typography>
                <Grid className={styles.culture_card_container}>
                    <Grid sx={{width:{xs:"100%", sm:"70%"}}}>
                        <img src="/assets/images/culture_img.png" alt="" style={{width: isMobile? "100%":""}} />
                        <Grid className={styles.culture_card_subcontainer}>
                            <Grid sx={{minWidth:"180px"}}>
                                <Typography variant="h3" component={"h3"} className={`${styles.culture_title} ${styles.colorWhite}`} sx={{fontWeight:600, marginBottom:"1rem"}}>
                                    27+
                                </Typography>
                                <Typography variant="body2" component={"p"} className={`${styles.culture_content} ${styles.colorWhite}`} sx={{borderTop:"2px solid #CEE86B", paddingTop:"0.4rem"}}>
                                    years of legacy
                                </Typography>
                            </Grid>
                            <Grid sx={{minWidth:"180px"}}>
                                <Typography variant="h3" component={"h3"} className={`${styles.culture_title} ${styles.colorWhite}`} sx={{fontWeight:600, marginBottom:"1rem"}}>
                                    60+
                                </Typography>
                                <Typography variant="body2" component={"p"} className={`${styles.culture_content} ${styles.colorWhite}`} sx={{borderTop:"2px solid #CEE86B", paddingTop:"0.4rem"}}>
                                    global clients
                                </Typography>
                            </Grid>
                            <Grid sx={{minWidth:"180px"}}>
                                <Typography variant="h3" component={"h3"} 
                                    className={`${styles.culture_title} ${styles.colorWhite}`} sx={{fontWeight:600, marginBottom:"1rem"}}>
                                    75+
                                </Typography>
                                <Typography variant="body2" component={"p"} className={`${styles.culture_content} ${styles.colorWhite}`} 
                                    sx={{borderTop:"2px solid #CEE86B", paddingTop:"0.4rem"}} dangerouslySetInnerHTML={{__html: isMobile ?"major projects delivered" : "major projects <br/> delivered"}}>
                                    
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{marginTop: isMobile? "10px": "0px" ,width:{xs:"100%", sm:"30%"}, backgroundColor:"#ECF2F6", borderRadius:"5px", padding:"36px 18px"}}>
                        <Typography variant="body2" component={"p"} className={styles.culture_content}>
                            Our employees are our greatest asset. We prioritize their well-being, growth, and development by fostering a supportive and inclusive work environment. From offering continuous learning opportunities to ensuring a healthy work-life balance, we are committed to empowering our people to reach their full potential and succeed together.
                        </Typography>
                        <Button className={styles.culture_btn} sx={{marginTop:"3rem"}} endIcon={<East />} onClick={() => navigate("/apply")}>Join us</Button>    
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>
        
    )
}

export default CultureProject;
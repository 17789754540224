import React from 'react';

import Grid from '@mui/material/Grid2';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';

import Slider from 'react-slick';
import { } from './whatsnew.css';
import { East, West } from '@mui/icons-material';
import styles from './whatsnew.module.css'
import { isMobile } from 'react-device-detect';


const News = () => {


    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-5%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-5%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    
    const settings = {
        dots: true,
        arrows: true,
        centerPadding: '30px',
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        draggable: false,
        // touchMove: false,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: false,
              centerMode: false,
            },
          },
          {
            breakpoint: 450,
            settings: {
                draggable: true,
                // touchMove: true,
              arrows: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: '10px',
              infinite: false,
              centerMode: false
            },
          },
        ]
      }

    const updateData = [
        <>
            <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                <CardMedia 
                sx={{ height: 216, marginBottom:1 }}
                image="/assets/images/news1.png"
                title="news1"
                />
                <CardContent className={styles.cardContent} >
                <Typography gutterBottom variant="h3" component="h3" className={`${styles.newscardtitle}`}>
                    Simon India Limited actively participated in India CHEM 2024, held in Mumbai
                </Typography>
                <Typography variant="body2" className={`${styles.newscard_content}`}>
                    October 17, 2024
                </Typography>
                </CardContent>
            </Card> 
        </>,
        <>
            <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                <CardMedia 
                sx={{ height: 216, marginBottom:1 }}
                image="/assets/images/news2.png"
                title="news1"
                />
                <CardContent className={styles.cardContent} style={{backgroundColor: "#ECF2F7"}}>
                    <Typography gutterBottom variant="h3" component="h3" className={`${styles.newscardtitle} ${styles.newscard_tcolor}`}>
                        Simon India Limited gains Insight into procurement processes
                    </Typography>
                    <Typography variant="body2" className={`${styles.newscard_content} ${styles.newscard_ccolor}`}>
                        August 10, 2024
                    </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                <CardMedia 
                sx={{ height: 216, marginBottom:1 }}
                image="/assets/images/news3.png"
                title="news1"
                />
                <CardContent style={{backgroundColor: "#ECF2F7"}} className={styles.cardContent}>
                <Typography gutterBottom variant="h3" component="h3" className={`${styles.newscardtitle} ${styles.newscard_tcolor}`}>
                We participate in the New Delhi Roundtable on Hydrogen Technology
                </Typography>
                <Typography variant="body2" className={`${styles.newscard_content} ${styles.newscard_ccolor}`}>
                    August 10, 2024
                </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                <CardMedia 
                sx={{ height: 216, marginBottom:1 }}
                image="/assets/images/news4.png"
                title="news1"
                />
                <CardContent style={{backgroundColor: "#ECF2F7"}} className={styles.cardContent}>
                <Typography gutterBottom variant="h3" component="h3" className={`${styles.newscardtitle} ${styles.newscard_tcolor}`}>
                    Simon India Limited secures major contract with Paradeep Phosphates Limited
                </Typography>
                <Typography variant="body2" className={`${styles.newscard_content} ${styles.newscard_ccolor}`}>
                    July 31, 2024
                </Typography>
                </CardContent>
            </Card>
        </>
    ]

    return (
        <Grid className={styles.whatsnew_container} sx={{paddingBottom: "100px"}}>
            <Grid className={styles.whatsnew_subcontainer}>
                <Typography variant='h3' component={"h3"} className={styles.whatsnew_title} 
                    sx={{marginBottom:"3rem", paddingLeft: isMobile? "18px" : "0px", paddingRight: isMobile? "18px" : "0px" }}>Updates</Typography>
                <Grid className={styles.cardContainer}>
                    <Slider {...settings}>
                        {updateData}
                    </Slider>
                </Grid>
                <Grid sx={{width:"100%", display:"flex", justifyContent: 'flex-end', marginTop: '30px'}}>
                    {/* <Button variant='text' className={styles.newsButton} endIcon={<ArrowForward />}>View all</Button> */}
                </Grid>
            </Grid>

        </Grid>
    )

}

export default News;
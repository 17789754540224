import React from 'react';
import Grid from '@mui/material/Grid2';
// import Slider from 'react-slick';
import styles from './valuePreposition.module.css'
import { Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';


const CorporateValue = () => {

    // const settings ={
    //     dots: false,
    //     arrows: false,
    //     dotsClass: 'slick-dots',
    //     className: 'center',
    //     centerMode: false,
    //     infinite: true,
    //     centerPadding: '30px',
    //     slidesToShow: 5,
    //     slidesToScroll: Math.min(1),
    //     swipeToSlide: false,
    //     speed: 500,
    //     responsive: [
    //        { 
    //         breakpoint: 1025,
    //             setting: {

    //             }
    //         }
    //     ]
    // }

    // const corporateData = [
    //     <><img src="/assets/images/value_img1.png" alt=""/></>,
    //     <><img src="/assets/images/value_img2.png" alt=""/></>,
    //     <><img src="/assets/images/value_img3.png" alt=""/></>,
    //     <><img src="/assets/images/value_img4.png" alt=""/></>,
    //     <><img src="/assets/images/value_img5.png" alt=""/></>
    // ]

return (
    <Grid className={styles.value_container} sx={{backgroundColor: "#ECF2F6", paddingTop: '100px', paddingBottom: '100px', paddingLeft: isMobile ? "18px" : 0, paddingRight: isMobile ? "18px" : 0}}>
        <Grid className={styles.value_subcontainer} >
        <Typography variant="h3" component={"h3"} className={styles.sub_title} sx={{width:'100%', textAlign:'center', marginBottom: "60px"}}>Corporate Values</Typography>
        <Grid className={styles.value_card_container}>
            <img src="/assets/images/value_img1.png" alt=""/>
            <img src="/assets/images/value_img2.png" alt=""/>
            <img src="/assets/images/value_img3.png" alt=""/>
            <img src="/assets/images/value_img4.png" alt=""/>
            <img src="/assets/images/value_img5.png" alt=""/>
            {/* <Slider {...settings}>{corporateData}</Slider> */}
        </Grid>
        </Grid>
    </Grid>
)

}

export default CorporateValue;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import Slider from 'react-slick';
import { } from './homeNews.css';
import styles from './homeNews.module.css'
import { ArrowForward } from '@mui/icons-material';


const HomeNews = () => {
    const navigate = useNavigate();
    
    const settings = {
        dots: false,
        arrows: false,
        centerPadding: '10px',
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        draggable: false,
        // touchMove: false,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: false,
              draggable: false,
            },
          },
          {
            breakpoint: 450,
            settings: {
                draggable: true,
                // touchMove: true,
              arrows: false,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: '10px',
              infinite: false,
              centerMode: false
            },
          },
        ]
      }

    const updateData = [
        <>
            <Card className={styles.card_container}>
                <CardMedia 
                sx={{ height: 216, marginBottom:1 }}
                image="/assets/images/news1.png"
                title="news1"
                />
                <CardContent className={styles.cardContent} >
                <Typography gutterBottom variant="h3" component="h3" className={`${styles.newscardtitle}`}>
                    Simon India Limited actively participated in India CHEM 2024, held in Mumbai
                </Typography>
                <Typography variant="body2" className={`${styles.newscard_content}`}>
                    October 17, 2024
                </Typography>
                </CardContent>
            </Card> 
        </>,
        <>
            <Card  className={styles.card_container}>
                <CardMedia 
                sx={{ height: 216, marginBottom:1 }}
                image="/assets/images/news2.png"
                title="news1"
                />
                <CardContent className={styles.cardContent}sx={{backgroundColor: "#ECF2F7"}}>
                    <Typography gutterBottom variant="h3" component="h3" className={`${styles.newscardtitle} ${styles.newscard_tcolor}`}>
                        Simon India Limited gains Insight into procurement processes
                    </Typography>
                    <Typography variant="body2" className={`${styles.newscard_content} ${styles.newscard_ccolor}`}>
                        August 10, 2024
                    </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.card_container}>
                <CardMedia 
                sx={{ height: 216, marginBottom:1 }}
                image="/assets/images/news3.png"
                title="news1"
                />
                <CardContent sx={{backgroundColor: "#ECF2F7"}} className={styles.cardContent}>
                <Typography gutterBottom variant="h3" component="h3" className={`${styles.newscardtitle} ${styles.newscard_tcolor}`}>
                We participate in the New Delhi Roundtable on Hydrogen Technology
                </Typography>
                <Typography variant="body2" className={`${styles.newscard_content} ${styles.newscard_ccolor}`}>
                    August 10, 2024
                </Typography>
                </CardContent>
            </Card>
        </>
    ]


    return (
        <Grid className={styles.homenews_container}>
            <Grid className={styles.homenews_subcontainer}>
                <Typography variant='h2' component={"h2"} className={styles.news_title}>Updates</Typography>
                <Grid className={`${styles.cardContainer} newsUpdate`}>
                    <Slider {...settings}>
                        {updateData}
                    </Slider>
                </Grid>
                <Grid className={styles.news_button}>
                    <Button variant='text' onClick={() => navigate("/news")} className={styles.newsButton} endIcon={<ArrowForward />}>View all</Button>
                </Grid>
            </Grid>

        </Grid>
    )

}

export default HomeNews;
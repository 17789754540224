import React from 'react';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import styles from './homeGrow.module.css';
import { Card, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { ArrowForward } from '@mui/icons-material';

const HomeGrow = () => {
    const navigate = useNavigate();

return (
    <Grid className={styles.grow_container}>
        <Grid className={styles.grow_subcontainer}>
            <Grid sx={{width:{xs:"100%", sm:"35%", md:"40%"}}}>
                <Typography variant="h2" component={"h2"} className={styles.grow_title}>Explore Ways We Can Grow Together</Typography>
                <Typography variant="body2" component={"p"} className={styles.grow_content}>
                    Partner with Simon India to unlock new opportunities. Our expertise in engineering, project management, and consulting ensures projects are executed flawlessly. Let's collaborate to drive innovation, efficiency, and growth in ventures across critical sectors.
                </Typography>
            </Grid>
            <Grid item sx={{width:{xs:"100%", sm:"65%", md:"60%"}}} className={styles.grows_card}>
                <Card className={styles.card_container}>
                    <CardMedia
                        className={styles.card_media}
                        image="/assets/images/grow_1.png"
                        title="contact"
                    />
                    <CardContent className={styles.card_content}>
                        <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Supply Partner</Typography>
                        <Button size="small" variant="text" endIcon={<ArrowForward />}
                            onClick={() => navigate("/grow-together/supply")}
                            className={styles.grow_button} >Register with us</Button>
                    </CardContent>
                   
                </Card>
                <Card className={styles.card_container}>
                    <CardMedia
                        className={styles.card_media}
                        image="/assets/images/grow_2.png"
                        title="contact"
                    />
                    <CardContent className={styles.card_content}>
                        <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Service Partner</Typography>
                        <Button size="small" variant="text" endIcon={<ArrowForward />} 
                            onClick={() => navigate("/grow-together/service")}
                            className={styles.grow_button} >Register with us</Button>
                    </CardContent>
                    
                </Card>
                <Card className={styles.card_container}>
                    <CardMedia
                        className={styles.card_media}
                        image="/assets/images/grow_3.png"
                        title="contact"
                    />
                    <CardContent className={styles.card_content}>
                        <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Empanel as SME</Typography>
                        <Button size="small" variant="text" endIcon={<ArrowForward />} 
                            onClick={() => navigate("/grow-together/empanel")}
                            className={styles.grow_button} >Join us</Button>
                    </CardContent>
                </Card>
            </Grid>

        </Grid>
    </Grid>
)

}

export default HomeGrow;
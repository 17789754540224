import React, { useState} from 'react';
import Grid from '@mui/material/Grid2';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import Slider from 'react-slick';
import { East, West } from '@mui/icons-material';

import styles from './services.module.css'
import { isMobile } from 'react-device-detect';

const ServiceProvide = () => {

    const [serviceIndex, setServiceIndex] = useState(0);

    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-1%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-1%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }

    const settings = {
        dots: false,
        arrows: false,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: false,
        centerPadding: '20px',
        slidesToShow: 6,
        
        slidesToScroll: Math.min(1),
        swipeToSlide: false,
        draggable: false,
        speed: 500,
        responsive: [
            { 
            breakpoint: 1025,
                settings: {
                    arrows: false,
                    slidesToShow: 4,
                    dots: true,
                    centerMode: true,
                    infinite: false,
                    swipeToSlide: true,
                }
            },
            {
                breakpoint: 450,
                settings:{
                    arrows: false,
                    dots: true,
                    infinite: false,
                    swipeToSlide: true,
                    slidesToShow: 1,
                    afterChange: (e) => setServiceIndex(e),
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                }
            }
        ]
    }
    const serviceDetails = [
        {
            id: 0,
            title: "Engineering Services",
            img: "service0_img.jpg",
            content: `Our Engineering Services cover the entire project lifecycle, from initial site assessments to detailed engineering and field support. We provide innovative, customized engineering solutions that drive project success and meet the highest industry standards.
            <br/>
            <ul className=service_list>
            <li>Site Visit & Site Assessment</li>
            <li>Conceptual Design</li>
            <li>Technology Selection</li>
            <li>FEED/ BEP Review</li>
            <li>Residual Basic Engineering</li>
            <li>Process Engineering</li>
            <li>Detailed Engineering
                <ul className=service_nolist>
                    <li>Mechanical</li>
                    <li>Piping</li>
                    <li>Elect & Instrumentation</li>
                    <li>Civil & Structural</li>
                </ul>
            </li>
            <li>Field Engineering</li>
            `
        },
        {
            id: 1,
            title: "Procurement Services",
            img: "service1_img.jpg",
            content: `Simon India’s Procurement Services facilitate seamless and efficient project execution through strategic planning, vendor due diligence, and comprehensive vendor management. Our robust procurement processes ensure that each project is equipped with the best resources, delivered on time and within budget.
            <br/>
            <ul className=service_list>
            <li>Procurement Planning</li>
            <li>Vendor Identification</li>
            <li>Bid Process Management
                <ul className=service_nolist>
                    <li>RFQ</li>
                    <li>RFP</li>
                    <li>Bid Evaluation</li>
                    <li>PO Placement</li>
                </ul>
            </li>
            <li>Vendor Document Review/Approval</li>
            <li>Inspection Services</li>
            <li>Expediting Services</li>
            <li>Contract Management Services</li>
            `
        },
        {
            id: 2,
            title: "Construction Management Services",
            img: "service2_img.jpg",
            content: `Our Construction Management Services are designed to oversee every aspect of project construction with precision and care. We focus on quality control, cost management, and ensuring that all safety and regulatory standards are met.
            <br/>
            <ul className=service_list>
            <li>Site Supervision</li>
            <li>Quality Control</li>
            <li>Cost Control</li>
            <li>Review of Vendor Invoices</li>
            <li>HSE Services</li>
            <li>Commissioning Assistance</li>
            <li>PG Test Monitoring</li>
            <li>Change Order Management</li>
            <li>Contract Closure Assistance</li>
            <li>Dispute Resolution Assistance </li>
            `

        },
        {
            id: 3,
            title: "Project Management Services",
            img: "service3_img.jpg",
            content: `Simon India offers comprehensive Project Management Services, providing end-to-end support for your projects. Our services include planning, risk assessment, performance monitoring, and project closure, ensuring that your projects are completed on time and within scope.
            <br/>
            <ul className=service_list>
            <li>Project Planning & Design</li>
            <li>Creation of Detailed Project Plan</li>
            <li>Stakeholder Analysis</li>
            <li>Design of Governance Framework</li>
            <li>Risk Assessment & Monitoring</li>
            <li>Performance Reporting</li>
            <li>Change Order Management</li>
            <li>Project Closure Assistance</li>
            `

        },{
            id: 0,
            title: "Special Services",
            img: "service4_img.jpg",
            content: `Simon India’s Special Services are tailored to meet the unique needs of complex projects. From feasibility studies to digital transformation, we offer specialized expertise that drives innovation and ensures project success in today’s fast-evolving market.
            <br/>
            <ul className=service_list>
            <li>Pre-feasibility Study</li>
            <li>Detailed Feasibility Study</li>
            <li>Detailed Project Report</li>
            <li>Technical Feasibility: R & M Projects</li>
            <li>Technical Feasibility: Retrofit Projects</li>
            <li>Review of Pilot Projects</li>
            <li>Process Safety Studies</li>
            <li>Plant Automation</li>
            <li>Digital Transformation</li>
            `

        },
        {
            id: 0,
            title: "Technical Assistance",
            img: "service5_img.jpg",
            content: `Our Technical Assistance services provide critical support for project optimization, including due diligence, efficiency improvements, and debottlenecking. We help clients navigate complex technical challenges and ensure projects are executed with maximum efficiency.
            <br/>
            <ul className=service_list>
            <li>Technical Due Diligence</li>
            <li>Project Relocation</li>
            <li>Efficiency Improvement Projects</li>
            <li>Energy Conservation Projects</li>
            <li>Conversion of Pilot Projects to Commercial Scale</li>
            <li>Debottlenecking</li>
            `
        }
    ]
    const serviceData = [
        <>
            <Card className={styles.service_card} onClick={() => setServiceIndex(0)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className={serviceIndex === 0 ? styles.service_disablefilter : styles.service_filter}
                    image='/assets/images/service1_thumb.png'
                />
                <CardContent className={styles.service_card_content}
                    sx={{backgroundColor: serviceIndex === 0 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.service_card_line}sx={{ backgroundColor: serviceIndex === 0 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h3' component={'h3'} className={styles.service_title} sx={{color: serviceIndex === 0 ? "#00258A" : "#5A5A5A" }}>Engineering Services </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.service_card}  onClick={() => setServiceIndex(1)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    image='/assets/images/service2_thumb.png'
                    className={serviceIndex === 1 ? styles.service_disablefilter : styles.service_filter}
                />
                <CardContent className={styles.service_card_content}
                    sx={{backgroundColor: serviceIndex === 1 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.service_card_line}sx={{ backgroundColor: serviceIndex === 1 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.service_title} sx={{color: serviceIndex === 1 ? "#00258A" : "#5A5A5A" }}>Procurement Services </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.service_card}  onClick={() => setServiceIndex(2)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className={serviceIndex === 2 ? styles.service_disablefilter : styles.service_filter}
                    image='/assets/images/service3_thumb.png'
                />
                <CardContent className={styles.service_card_content}
                    sx={{backgroundColor: serviceIndex === 2 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.service_card_line}sx={{ backgroundColor: serviceIndex === 2 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.service_title} sx={{color: serviceIndex === 2 ? "#00258A" : "#5A5A5A" }}>Construction Management Services </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.service_card}  onClick={() => setServiceIndex(3)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    className={serviceIndex === 3 ? styles.service_disablefilter : styles.service_filter}
                    title=""
                    image='/assets/images/service4_thumb.png'
                />
                <CardContent className={styles.service_card_content}
                    sx={{backgroundColor: serviceIndex === 3 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.service_card_line}sx={{ backgroundColor: serviceIndex === 3 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.service_title} sx={{color: serviceIndex === 3 ? "#00258A" : "#5A5A5A" }}>Project Management Services </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.service_card}  onClick={() => setServiceIndex(4)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    className={serviceIndex === 4 ? styles.service_disablefilter : styles.service_filter}
                    title=""
                    image='/assets/images/service5_thumb.png'
                />
                <CardContent className={styles.service_card_content}
                    sx={{backgroundColor: serviceIndex === 4 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.service_card_line}sx={{ backgroundColor: serviceIndex === 4 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.service_title} sx={{color: serviceIndex === 4 ? "#00258A" : "#5A5A5A" }}>Special Services </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.service_card}  onClick={() => setServiceIndex(5)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className={serviceIndex === 5 ? styles.service_disablefilter : styles.service_filter}
                    image='/assets/images/service6_thumb.png'
                />
                <CardContent className={styles.service_card_content}
                    sx={{backgroundColor: serviceIndex === 5 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.service_card_line}sx={{ backgroundColor: serviceIndex === 5 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.service_title} sx={{color: serviceIndex === 5 ? "#00258A" : "#5A5A5A" }}>Technical Assistance </Typography>
                </CardContent>
            </Card>
        </>
    ]

    return (
        <>
        <Grid className={styles.service_container} 
            sx={{paddingTop:"100px", paddingLeft: isMobile? "36px": "0px", paddingRight: isMobile? "36px": "0px"}}>
            <Grid className={styles.service_subcontainer}>
                <Typography variant="h3" component={"h3"} className={styles.title} sx={{width:'100%', textAlign:'center', marginBottom: "30px", textTransform:"capitalize"}}>Services we provide</Typography>
                <Grid sx={{width: isMobile? "width: 60%":"100%" }}>
                    <Slider {...settings}>
                        {serviceData}
                    </Slider>
                </Grid>
            </Grid>
        </Grid>
        <Grid className={`${styles.service_container} ${styles.service_contain}`} >
            <Grid className={`${styles.service_subcontainer}`}>
                <Typography variant="h3" component={"h3"} className={styles.title} sx={{marginTop:"2rem", width:'100%', marginBottom: "30px"}}>{serviceDetails[serviceIndex].title}</Typography>
                <Grid className={styles.service_subcontain}>
                    <Grid sx={{width: {xs:'100%', md:'50%'}}}>
                        <Typography variant="body2" component={"p"} className={styles.service_content} 
                            dangerouslySetInnerHTML={{__html: serviceDetails[serviceIndex].content}} />
                            
                    </Grid>
                    <Grid sx={{width: {xs:'100%', md:'50%'}}}>
                        <img src= {`/assets/images/${serviceDetails[serviceIndex].img}`} alt={serviceDetails[serviceIndex].title} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        </>
    )


}

export default ServiceProvide;
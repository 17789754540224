import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import AboutStrength from './aboutStrength';
import AboutBrand from './aboutBrand'
import AboutExplore from './aboutExplore';
import AboutCustomer from './aboutCustomer';
import AboutStory from './aboutStory';
import AboutGroup from './aboutGroup';
import AboutMisson from './aboutMission';
import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';

const AboutUs = () => {
    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Know us', url: '/'},
        {title: 'About us', url: ''}
    ]

    useEffect(()=>{
            window.scrollTo({top:0, left:0})
    },[])



    return (
        <Grid sx={{display: 'flex', flexFlow:'column', width: '100%'}}>
            <PageBanner title="About Us" bannerimage="about_banner.jpg" bannerPath={bannerPath} />
            <PageContent details="Simon India specializes in EPC, EPCM, and PMC services for Chemicals & Fertilizers, Oil & Gas and Renewable Energy projects using sustainable practices and latest technologies.
<br/><br/>
As a part of Adventz, we have delivered 75+ major projects over 27 years, fostering customer satisfaction, employee motivation, and innovation. Our expertise spans critical sectors, providing tech-enabled solutions that drive efficiency and growth. 
<br/><br/>
Our commitment to quality and safety is unwavering, reflected in our adherence to international standards and industry best practices. We leverage the latest engineering techniques and proven project management methodologies to deliver superior results. Our dedicated team of professionals, equipped with extensive industry knowledge, deep engineering acumen, and an innovative approach to crafting solutions, ensures that every project meets the highest standards of excellence. 
<br/><br/>
Join us in building a sustainable and prosperous future." />
            <AboutMisson />
            <AboutStrength />
            <AboutBrand />
            <AboutExplore />
            <AboutCustomer />
            <AboutStory />
            <AboutGroup />
            <AdvantageC />
        </Grid>
    )

}

export default AboutUs
import React from 'react';
import Grid from '@mui/material/Grid2';
import CountUp from 'react-countup';
import styles from './homepossible.module.css';
import { Typography } from '@mui/material';
import { isMobile, isTablet } from 'react-device-detect';

const HomePossible = () => {

    return (
        <Grid className={styles.homepossible_container}>
            <Grid className={styles.redefined_maincontainer}>
            <Typography variant='h2' component={"h2"} className= {styles.redefined_heading}>
            Legacy of Engineering Excellence
            </Typography>
            {/* <Typography variant='' component={"p"} className={styles.redefined_text}>
                With our deep legacy of engineering excellence
            </Typography> */}

            <Grid container className={styles.redefined_container}>
                <Grid item sx={{width: {xs:"100%", sm:"20%"}, maxWidth: isMobile ? "100%":"180px"}}>
                    <Typography variant='h3' component={'h3'} className= {styles.redefined_bigtext} >
                        <CountUp delay={2} end={27} suffix="+" />
                    </Typography>
                    <div style={{width: '100%', borderTop: '1px solid #FFFFFF'}}>
                    <Typography variant='p' component={'p'} className= {styles.redefined_bigtext_subheading} 
                        dangerouslySetInnerHTML={{__html: isMobile ? "years of trusted expertise" : "years of trusted<br/>expertise"}}
                    />
                    
                    </div>
                </Grid>
                <Grid sx={{width: {xs:"100%", sm:"20%"}, maxWidth: isMobile? "100%":"180px"}}>
                    <Typography variant='h3' component={'h3'} className= {styles.redefined_bigtext} >
                    <CountUp delay={2} end={60} suffix="+" />
                    </Typography>
                    <div style={{width: '100%', borderTop: '1px solid #FFFFFF'}}>
                        <Typography variant='p' component={'p'} className= {styles.redefined_bigtext_subheading} 
                        dangerouslySetInnerHTML={{__html: isMobile ? "clients across the globe": "clients across <br/>the globe"}}
                        />
                        
                    </div>
                </Grid>
                <Grid sx={{width: {xs:"100%", sm:"20%"}, maxWidth: isMobile? "100%":"180px"}}>
                    <Typography variant='h3' component={'h3'} className= {styles.redefined_bigtext} >
                    <CountUp delay={2} end={75} suffix="+" />
                    </Typography>
                    <div style={{width: '100%', borderTop: '1px solid #FFFFFF'}}>
                    <Typography variant='p' component={'p'} className= {styles.redefined_bigtext_subheading} 
                        dangerouslySetInnerHTML={{__html: isMobile ? "projects delivered successfully": "projects delivered<br/>successfully"}}
                    />
                    </div>
                </Grid>
                <Grid sx={{width: {xs:"50%", sm:"40%"}}}>
                    <div className={styles.redefined_image}>
                        <img src='/assets/images/redefine-img.png' alt="redefine" />
                        {/* <img src='/assets/images/redefine-img.png' alt="redefine" /> */}
                    </div>
                </Grid>
                
            </Grid>
        </Grid>
    </Grid>

    )
}

export default HomePossible;
import React,{useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';
import ExploreProject from '../../components/exploreProject';
import ProjectClient from './projectClient';
import ExpertiseArea from './expertiseArea';
import DeliveryApproach from './deliveryApproach';


const Expertise = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: "Expertise", url: ''}
    ]

    useEffect(()=>{
            window.scrollTo({top:0, left:0})
    },[])


return (
    <Grid sx={{width: "100%"}}>
        <PageBanner title="Expertise" bannerimage="expertise_banner.jpg" 
            bannerPath={bannerPath}  />
        <PageContent 
           details="At Simon India Limited, our diverse capabilities span four pivotal sectors: Chemicals & Fertilizers, Oil & Gas and Renewable Energy. As a leading EPCM service provider, we are adept at delivering tailored solutions that cater to the specific needs of each sector. Our experience is backed by advanced technical know-how, ensuring that projects are executed with precision, efficiency and adherence to the highest industry standards." />
        {/* <ExpertiseProject /> */}
        <ExpertiseArea />
        <ProjectClient 
            quotation="Simon India stands as a leader in the engineering sector, delivering innovative and diverse solutions across Chemicals, Fertilizers, Petrochemicals and Power. With a focus on cutting-edge technology, sustainable practices, and meticulous project management, we ensure on-time, high-quality outcomes. Our client-centric approach drives long-term success, making us a trusted partner in complex engineering projects."
            client="Mr. Harish Kumar"
            designation="Head - Marketing"
        />
        <DeliveryApproach />
        <ExploreProject />
        <AdvantageC />
    </Grid>
)

}

export default Expertise;
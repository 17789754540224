import React, {useRef} from 'react';
import Grid from '@mui/material/Grid2';

import { } from './clients.css';
import styles from './clients.module.css'
import { IconButton, Typography } from '@mui/material';
import { East, West } from '@mui/icons-material';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';

const Clients = (props) => {

    const {containerColor, marginT} = props;

    const clientsRef = useRef(null);

    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-8%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-8%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }

    const settings= {
        dots: false,
        arrows: false,
        className: 'center',
        centerMode: false,
        infinite: true,
        centerPadding: '30px',
        slidesToShow: Math.min(2, 1),
        slidesToScroll: Math.min(1),
        speed: 500,
        responsive: [
           { 
            breakpoint: 1025,
                settings: {

                }
            },
            { 
            breakpoint: 450,
                settings: {
                    arrows: false,
                    dots: true,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                    
                }
            }
        ]
    }

    const slidePrev = (e) => {
        e.preventDefault()
        clientsRef.current.slickPrev();
    }
    const slideNext = (e) => {
        e.preventDefault()
        clientsRef.current.slickNext();
    }

    
    const clientData = [
        <>
            <div className=''>
                <div className={styles.userBack}>
                    <div className={styles.userDiv}>
                        <img src="/assets/images/rahul.png" alt="rahul" style={{marginTop:"-130px"}} />
                    </div>
                    <div className={styles.usercontent_div} style={{backgroundColor:`${containerColor}`}}>
                        <Typography variant='body2' component={"p"} className={styles.client_user_title}>Rohit Sehgal</Typography>
                        <Typography variant='body2' component={"p"} className={styles.client_user_content}>VP Operations</Typography>
                        <Typography variant='body2' component={"p"} className={styles.client_user_content}>Adani Petrochemicals</Typography>
                    </div>
                </div>
                <Typography variant='body2' component={"p"} className={styles.client_feed}>Their recommendations and guidance on the project was invaluable. Their expertise has been invaluable and experience warm. Their recommendations and guidance on the project was invaluable. Their expertise has been invaluable and experience warm.
                <br/><br/>Their expertise has been invaluable and experience warm. Their recommendations and guidance on the project was invaluable. Their expertise has been invaluable and experience warm.</Typography>
            </div>
        </>,
        <>
        <div>
            <div className={styles.userBack}>
                <div className={styles.userDiv}>
                    <img src="/assets/images/rahul.png" alt="rahul" style={{marginTop:"-130px"}} />
                </div>
                <div className={styles.usercontent_div} style={{backgroundColor:`${containerColor}`}}>
                    <Typography variant='body2' component={"p"} className={styles.client_user_title}>Rohit Sehgal</Typography>
                    <Typography variant='body2' component={"p"} className={styles.client_user_content}>VP Operations</Typography>
                    <Typography variant='body2' component={"p"} className={styles.client_user_content}>Adani Petrochemicals</Typography>
                </div>
            </div>
            <Typography variant='body2' component={"p"} className={styles.client_feed}>Their recommendations and guidance on the project was invaluable. Their expertise has been invaluable and experience warm. Their recommendations and guidance on the project was invaluable. Their expertise has been invaluable and experience warm.
                <br/><br/>Their expertise has been invaluable and experience warm. Their recommendations and guidance on the project was invaluable. Their expertise has been invaluable and experience warm.</Typography>
        </div>
    </>
    ]
return (
    <Grid className={`${styles.client_container}`}>
        <Grid className={styles.client_subcontainers} sx={{marginTop:`${marginT}`, backgroundColor:`${containerColor}`}}> 
            <Grid className={styles.client_subcontainer}>
                <Typography variant='h3' component={"h3"} className={`${styles.client_title} ${styles.client_mob}`} 
                    sx={{display: isMobile? 'block': 'none'}}>What our clients say</Typography>
                <Grid className={styles.client_arrow}>
                    <img src="/assets/images/rightarrow.png" alt="" /> 
                </Grid>
                <Grid style={{display: 'flex'}} className={styles.client_holder}>
                    <Grid className={styles.client_header} sx={{width: {xs:'100%', sm:'30%', md:"40%"}, marginTop:"115px" }}>
                        <Typography variant='h3' component={"h3"} className={styles.client_title}>What our clients say</Typography>
                        <div style={{display:"none"}}>
                            <IconButton onClick={slidePrev} sx={{color:"#2182FF"}}><West /></IconButton>
                            <IconButton onClick={slideNext} sx={{color:"#2182FF"}}><East /></IconButton>
                        </div>
                    </Grid>
                    <Grid className="client_slider" sx={{width: {xs:'100%', sm:'60%'}, marginRight: isMobile ? "18px" : "0px"}}>
                        <div className=''>
                            <div className={styles.userBack}>
                                <div className={styles.userDiv}>
                                    <img src="/assets/images/clients/client_5.png" alt="rahul" style={{marginTop:"0px"}} />
                                </div>
                                <div className={styles.usercontent_div} style={{backgroundColor:`${containerColor}`}}>
                                    <Typography variant='body2' component={"p"} className={styles.client_user_title}>KLJ, Qatar</Typography>
                                    <Typography variant='body2' component={"p"} className={styles.client_user_content}>Detailed engineering & project management services for 200 MTPD Caustic Soda plant</Typography>
                                </div>
                            </div>
                            <Typography variant='body2' component={"p"} className={styles.client_feed}>The Simon India team provided exceptional detailed engineering and project management services for our Caustic Soda plant. Their technical expertise, attention to detail, and proactive project management ensured smooth execution from start to finish. We were thoroughly impressed with their professionalism, timely delivery, and ability to meet our project goals with the highest quality standards.</Typography>
                        </div>

                        <Slider ref= {clientsRef} {...settings}>
                            {clientData}
                        </Slider>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)


}

export default Clients;
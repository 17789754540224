import React from 'react';
import Grid from '@mui/material/Grid2';

import styles from './whatsnew.module.css';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';


const Awards = () => {
    return (
        <Grid className={styles.whatsnew_container} sx={{paddingTop: "100px", paddingBottom: "100px"}}>
            <Grid className={styles.whatsnew_subcontainer}>
                <Grid className={styles.whats_title_container}>
                    <Typography variant='h3' component={"h3"} className={styles.whatsnew_title}>
                        Awards & Recognitions
                    </Typography>
                    <img src="/assets/images/award_title_icon.png" alt="" />
                </Grid>
                <Grid className={styles.award_card_container}>
                    <Card className={styles.award_card}>
                        <CardMedia
                            image='/assets/images/award1_icon.png'
                            className={styles.award_card_media}
                        />
                        <CardContent sx={{padding:0, marginTop:'2rem'}}>
                                <Typography variant='body2' component={"p"} className={styles.whatsnew_subtitle} sx={{marginBottom:'1rem', textAlign:'center'}}>
                                        SABIC Safety Award
                                </Typography>
                            <Grid className={styles.award_card_grid}>
                                <Typography variant='body2' component={"p"} className={styles.whatsnew_content} sx={{marginBottom:'1rem'}}>
                                    Awarded the prestigious SABIC Safety Award in the Mega Contractors Category
                                </Typography>
                            </Grid>    
                        </CardContent>
                    </Card>
                    <Card className={styles.award_card}>
                        <CardMedia
                            image='/assets/images/award2_icon.png'
                            className={styles.award_card_media}
                        />
                        <CardContent sx={{padding:0, marginTop:'2rem'}}>
                                <Typography variant='body2' component={"p"} className={styles.whatsnew_subtitle} sx={{marginBottom:'1rem', textAlign:'center'}}>
                                Performance Achievement Award
                                </Typography>
                            <Grid className={styles.award_card_grid}>
                                <Typography variant='body2' component={"p"} className={styles.whatsnew_content} sx={{marginBottom:'1rem'}}>
                                    Awarded the Performance Achievement Award for executing Urea Granulation and Ammonia Abatement & Flare System Project at Al-Jubail in KSA
                                </Typography>
                            </Grid>    
                        </CardContent>
                    </Card>
                    <Card className={styles.award_card}>
                        <CardMedia
                            image='/assets/images/award3_icon.png'
                            className={styles.award_card_media}
                        />
                        <CardContent sx={{padding:0, marginTop:'2rem'}}>
                                <Typography variant='body2' component={"p"} className={styles.whatsnew_subtitle} sx={{marginBottom:'1rem', textAlign:'center'}}>
                                    1st Prize by TEKLA
                                </Typography>
                            <Grid className={styles.award_card_grid}>
                                <Typography variant='body2' component={"p"} className={styles.whatsnew_content} sx={{marginBottom:'1rem'}}>
                                    Awarded 1st Prize by TEKLA for Special Projects Category and Complex Modelling
                                </Typography>
                            </Grid>    
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )


}

export default Awards;
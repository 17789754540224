import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import Slider from 'react-slick';
import { East, West } from '@mui/icons-material';



import styles from './expertise.module.css'
import { isMobile } from 'react-device-detect';


const ExpertiseArea = () => {


    const [serviceIndex, setServiceIndex] = useState(0);

    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-1%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-1%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }

    const settings = {
        dots: false,
        arrows: false,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: false,
        centerPadding: '20px',
        slidesToShow: 4,
        
        slidesToScroll: Math.min(1),
        swipeToSlide: false,
        speed: 500,
        responsive: [
            { 
            breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    arrows: false,
                    dots: true,
                }
            },
            {
                breakpoint: 450,
                settings:{
                    arrows: false,
                    dots: true,
                    infinite: false,
                    centerMode: true,
                    swipeToSlide: true,
                    slidesToShow: 1,
                    afterChange: (e) => setServiceIndex(e),
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                }
            }
        ]
    }

    const expertDetails = [
        {
            id: 0,
            title: "Fertilizers: Maximizing Output, Minimizing Environmental Impact",
            img:"expert_fertilizer.png",
            thumb_img: "thumb_fertilizer.png",
            content: `We are the engineering arm of one of India's largest Fertilizer groups. Our services include the development of production plants for key fertilizers such as urea, ammonia, DAP and NPK. Simon India’s advanced plant design focuses on maximizing output while minimizing environmental impact, making us a trusted partner for building facilities to meet the growing global demand and contribute to sustainable agricultural practices.`,
            
        },
        {
            id: 0,
            title: "Chemicals: Playing<br/> a Pivotal Role",
            img: "expert_chemical.png",
            thumb_img: "thumb_chemical.png",
            content: `Simon India plays a pivotal role in constructing facilities to produce vital chemicals such as caustic soda, chlorine, sulfuric acid and phosphoric acid. Our EPCM solutions ensure that chemical plants are designed with cutting-edge technology, resulting in optimized operations and enhanced output. With expertise in setting up chlor-alkali plants, we help drive critical industrial processes that support numerous sectors including water treatment, pharmaceuticals, and manufacturing.`
        },
        {
            id: 0,
            title: "Oil & Gas: Providing Comprehensive<br/> EPCM Expertise",
            img: "expert_oil.png",
            thumb_img: "thumb_oil.png",
            content: `Our expertise in the oil & gas domain covers comprehensive EPCM solutions for both refining and gas processing facilities. Simon India specializes in plant upgrades, capacity enhancements, and sustainable energy processes, ensuring compliance with stringent safety and environmental standards.`,
        },
        {
            id: 0,
            title: "Renewable Energy: Presenting<br/> Significant Capabilities",
            content: `Simon India is expanding significantly the capabilities in the renewable energy sector. We now offers a comprehensive suite of services designed to support and enable green energy initiatives. Our team of experts is equipped with the knowledge and experience necessary to navigate every stage of renewable energy projects, ensuring a seamless transition from concept to completion.`,
            img: "expert_energy.png",
            thumb_img: "thumb_energy.png",
        }
    ]

    const serviceData = [
        <>
            <Card className={styles.expert_card} onClick={() => setServiceIndex(0)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className= {serviceIndex === 0 ? styles.disable_filter : styles.apply_filter}
                    image='/assets/images/expertise1_thumb.png'
                />
                <CardContent className={styles.expert_card_content}
                    sx={{backgroundColor: serviceIndex === 0 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.expert_card_line} sx={{ backgroundColor: serviceIndex === 0 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h3' component={'h3'} className={styles.expert_title} sx={{color: serviceIndex === 0 ? "#00258A" : "#5A5A5A" }}>Fertilizers</Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.expert_card} onClick={() => setServiceIndex(1)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className= {serviceIndex === 1 ? styles.disable_filter : styles.apply_filter}
                    image='/assets/images/expertise2_thumb.png'
                />
                <CardContent className={styles.expert_card_content}
                    sx={{ backgroundColor: serviceIndex === 1 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.expert_card_line} sx={{ backgroundColor: serviceIndex === 1 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h3' component={'h3'} className={styles.expert_title} sx={{color: serviceIndex === 1 ? "#00258A" : "#5A5A5A" }}>Chemicals</Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.expert_card} onClick={() => setServiceIndex(2)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className= {serviceIndex === 2 ? styles.disable_filter : styles.apply_filter}
                    image='/assets/images/expertise3_thumb.png'
                />
                <CardContent className={styles.expert_card_content}
                    sx={{ backgroundColor: serviceIndex === 2 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.expert_card_line} sx={{ backgroundColor: serviceIndex === 2 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.expert_title} sx={{color: serviceIndex === 2 ? "#00258A" : "#5A5A5A" }}>Oil & Gas </Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card className={styles.expert_card}  onClick={() => setServiceIndex(3)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 155}} 
                    title=""
                    className= {serviceIndex === 3 ? styles.disable_filter : styles.apply_filter}
                    image='/assets/images/expertise4_thumb.png'
                />
                <CardContent className={styles.expert_card_content}
                    sx={{ backgroundColor: serviceIndex === 3 ? "#CEE86B": "#FFF"}}>
                    <Grid className={styles.expert_card_line} sx={{ backgroundColor: serviceIndex === 3 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.expert_title} sx={{color: serviceIndex === 3 ? "#00258A" : "#5A5A5A" }}>Renewable Energy </Typography>
                </CardContent>
            </Card>
        </>
    ]




    return (
        <>
        <Grid className={styles.expertise_container} 
            sx={{paddingTop:"80px", paddingBottom: "100px", paddingLeft: isMobile? "36px": "0px", paddingRight: isMobile? "36px": "0px"}}>
            <Grid className={`${styles.expertise_subcontainer} ${styles.expertise_card}`} 
                >
                <Typography variant="h3" component={"h3"} className={styles.expertise_title} sx={{width:{xs:"100%", sm:"30%", marginTop: "-4rem"}, textAlign:'left', marginBottom: "30px"}}>Areas of Expertise</Typography>
                <Grid sx={{width:{xs:"100%", sm:"70%"}}}>
                    <Slider {...settings}>
                        {serviceData}
                    </Slider>
                </Grid>
            </Grid>
        </Grid>
        <Grid className={`${styles.expertise_container} ${styles.expertise_area}`} >
            <Grid className={`${styles.expertise_subcontainer} ${styles.expertise_subarea}`}>
                <img src= {`/assets/images/${expertDetails[serviceIndex].img}`} alt="" style={{alignSelf: "flex-start", width: isMobile? "50px": ""}} />
                <Grid sx={{display:"flex", marginTop: "1.5rem" }}>
                    <Grid sx={{width:{xs:"100%", md:"50%"}, minHeight:"356px"}}>
                        <Typography variant="h2" component={"h2"} className={styles.expertise_card_title} 
                            sx={{marginTop: isMobile ? "1rem":"2rem", width:'100%', marginBottom: "30px"}}
                            dangerouslySetInnerHTML={{__html: expertDetails[serviceIndex].title }}
                        />
                        <Grid sx={{display: 'flex', width: '100%', gap: '30px'}}>
                            <Grid sx={{width: {xs:'100%'}}}>
                                <Typography variant="body2" component={"p"} className={styles.expertise_content} 
                                dangerouslySetInnerHTML={{__html: expertDetails[serviceIndex].content}} />       
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{width:{xs:"100%", sm:"50%"}, display: isMobile? "none" :"flex", justifyContent: "center", alignItems:"center"}}>
                        <img src= {`/assets/images/${expertDetails[serviceIndex].thumb_img}`} alt="" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </>
)
}
export default ExpertiseArea;